import React, { useState, useEffect, useContext } from 'react';
import createAuth0Client, { Auth0Client } from '@auth0/auth0-spa-js';
import { navigate } from 'gatsby';

const onRedirectCallback = (appState) =>
  navigate(
    appState && appState.redirecTo
      ? appState.redirecTo
      : window.location.pathname
  );

export const Auth0Context = React.createContext();

export const useAuth0 = () => useContext(Auth0Context);

export const Auth0Provider = ({ children, ...initOptions }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState(null);
  const [error, setError] = useState(false);
  const [auth0Client, setAuth0] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const initAuth0 = async () => {
      let auth0FromHook;

      try {
        auth0FromHook = await createAuth0Client(initOptions);
      } catch (e) {
        auth0FromHook = new Auth0Client(initOptions);
      }

      setAuth0(auth0FromHook);

      if (
        window.location.search.includes('code=') &&
        window.location.search.includes('state=')
      ) {
        const { appState } = await auth0FromHook.handleRedirectCallback();
        await onRedirectCallback(appState);
      } else if (window.location.search.includes('error=')) {
        setError(true);
      }

      const isAuthenticated = await auth0FromHook.isAuthenticated();

      setIsAuthenticated(isAuthenticated);

      if (isAuthenticated) {
        const user = await auth0FromHook.getUser();
        setUser(user);
      }

      setLoading(false);
    };

    initAuth0().catch((e) => {
      setError(true);
      setLoading(false);
      setUser(null);
      setIsAuthenticated(false);
    });

    // eslint-disable-next-line
  }, []);

  return (
    <Auth0Context.Provider
      value={{
        isAuthenticated,
        user,
        loading,
        error,
        loginWithRedirect: (...p) => auth0Client.loginWithRedirect(...p),
        logout: (...p) => auth0Client.logout(...p),
      }}
    >
      {children}
    </Auth0Context.Provider>
  );
};
