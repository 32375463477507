import { mergeDeepRight } from 'ramda';

import {
  SET_REVIEW_COMMENT,
  SET_RATINGS,
  SUBMIT_USERS_REVIEW_ERROR,
  SUBMIT_USERS_REVIEW_SUCCESS,
} from './constants';

const initialState = {};

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case SUBMIT_USERS_REVIEW_ERROR: {
      return state;
    }

    case SUBMIT_USERS_REVIEW_SUCCESS: {
      const filteredReviews = { ...state[payload.cityTitle] };
      filteredReviews[payload.hoodTitle] = null;
      delete filteredReviews[payload.hoodTitle];

      return { ...state, [payload.cityTitle]: filteredReviews };
    }

    case SET_RATINGS: {
      return mergeDeepRight(state, {
        [payload.cityTitle]: {
          [payload.hoodTitle]: {
            userRatings: {
              [payload.type]: payload.rating,
            },
          },
        },
      });
    }

    case SET_REVIEW_COMMENT: {
      return mergeDeepRight(state, {
        [payload.cityTitle]: {
          [payload.hoodTitle]: {
            comment: payload.comment,
          },
        },
      });
    }

    default:
      return state;
  }
}
