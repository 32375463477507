export const HOOD_LIST_TITLE_SORTING = 'title';
export const HOOD_LIST_RATING_SORTING = 'rating';
export const HOOD_LIST_REVIEWS_AMOUNT_SORTING = 'reviewsAmount';
export const HOOD_LIST_DISTRICTS_SORTING = 'districts';

export const sortingFields = [
  HOOD_LIST_TITLE_SORTING,
  HOOD_LIST_RATING_SORTING,
  HOOD_LIST_REVIEWS_AMOUNT_SORTING,
  HOOD_LIST_DISTRICTS_SORTING,
];

export const getSortedHoodsList = ({
  hoodsList,
  sortingType,
  searchQuery,
  ratings,
}) => {
  let sortingFunction;

  switch (sortingType) {
    case HOOD_LIST_RATING_SORTING:
      sortingFunction = (a, b) =>
        sortByRatingFieldName(a, b, ratings || {}, 'totalRating');
      break;
    case HOOD_LIST_REVIEWS_AMOUNT_SORTING:
      sortingFunction = (a, b) =>
        sortByRatingFieldName(a, b, ratings || {}, 'reviewsAmount');
      break;
    case HOOD_LIST_DISTRICTS_SORTING:
      sortingFunction = (a, b) => sortByFieldName(a, b, 'districtName');
      break;
    case HOOD_LIST_TITLE_SORTING:
    default:
      sortingFunction = (a, b) => sortByFieldName(a, b, 'hoodTitle');
      break;
  }

  let result = hoodsList.sort(sortingFunction);

  if (searchQuery) {
    result = result.filter((item) =>
      item.hoodTitle.toLowerCase().includes(searchQuery.trim().toLowerCase())
    );
  }

  return result;
};

const sortByFieldName = (a, b, fieldName) => {
  if (a[fieldName] < b[fieldName]) {
    return -1;
  }
  if (b[fieldName] < a[fieldName]) {
    return 1;
  }
  return 0;
};

const sortByRatingFieldName = (a, b, ratings, ratingFieldName) => {
  const aID = a.hoodTitle;
  const bID = b.hoodTitle;

  if (!ratings[aID] && !ratings[bID]) {
    return 0;
  }

  if (!ratings[aID]) {
    return 1;
  }

  if (!ratings[bID]) {
    return -1;
  }

  return +ratings[bID][ratingFieldName] - +ratings[aID][ratingFieldName];
};
