export const SET_HOOD_LIST_SORTING_TYPE = 'SET_HOOD_LIST_SORTING_TYPE';
export const SET_HOOD_LIST_SEARCH_QUERY = 'SET_HOOD_LIST_SEARCH_QUERY';

export const SET_RATING_FILTERS = 'SET_RATING_FILTERS';
export const TOGGLE_ALL_RATING_FILTERS = 'TOGGLE_ALL_RATING_FILTERS';

export const SET_LOG_IN_START = 'SET_LOG_IN_START';
export const SET_LOG_OUT_START = 'SET_LOG_OUT_START';

export const TOGGLE_IS_MOBILE_MAP_HIDDEN = 'TOGGLE_IS_MOBILE_MAP_HIDDEN';
