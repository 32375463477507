import { SET_USER_MODAL_IS_OPEN, SET_FILTERS_MODAL_IS_OPEN } from './constants';

const initialState = {
  filtersModalIsOpen: false,
  userModalIsOpen: false,
};

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case SET_USER_MODAL_IS_OPEN:
      return {
        ...state,
        userModalIsOpen: payload,
      };

    case SET_FILTERS_MODAL_IS_OPEN:
      return {
        ...state,
        filtersModalIsOpen: payload,
      };

    default:
      return state;
  }
}
