import { mergeDeepRight } from 'ramda';

import {
  FETCH_HOODS_BY_CITY_SUCCESS,
  FETCH_HOODS_BY_CITY_ERROR,
  FETCH_HOOD_ERROR,
  FETCH_HOOD_SUCCESS,
  FETCH_HOOD_START,
} from './constants';
import { SUBMIT_USERS_REVIEW_SUCCESS } from '../usersReview/constants';
import { DELETE_REVIEW_SUCCESS } from '../reviews/constants';
import {
  SET_RATING_FILTERS,
  TOGGLE_ALL_RATING_FILTERS,
} from '../settings/constants';

import { getTotalRating } from '../../utils/rating-helper';

const initialState = {};

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case FETCH_HOOD_START:
      return mergeDeepRight(state, {
        [payload.cityTitle]: {
          data: {
            [payload.hoodTitle]: {
              ratings: null,
              loaded: false,
              error: false,
            },
          },
        },
      });

    case FETCH_HOOD_ERROR:
      return mergeDeepRight(state, {
        [payload.cityTitle]: {
          data: { [payload.hoodTitle]: { loaded: true, error: payload.name } },
        },
      });

    case FETCH_HOOD_SUCCESS:
    case SUBMIT_USERS_REVIEW_SUCCESS:
      return mergeDeepRight(state, {
        [payload.cityTitle]: {
          data: {
            [payload.hoodTitle]: {
              ratings: payload.ratings,
              reviewsAmount: payload.reviewsAmount,
              totalRating: getTotalRating(
                payload.ratings,
                payload.ratingFilters
              ),
              loaded: true,
              error: false,
            },
          },
        },
      });

    case DELETE_REVIEW_SUCCESS: {
      return {
        ...state,
        [payload.cityTitle]: {
          data: {
            ...state[payload.cityTitle].data,
            [payload.hoodTitle]: {
              ratings: payload.ratings,
              reviewsAmount: payload.reviewsAmount,
              totalRating: getTotalRating(
                payload.ratings,
                payload.ratingFilters
              ),
              loaded: true,
              error: false,
            },
          },
        },
      };
    }

    case FETCH_HOODS_BY_CITY_ERROR:
      return mergeDeepRight(state, {
        [payload.cityTitle]: {
          fetchHoodsByCitySuccess: false,
        },
      });

    case FETCH_HOODS_BY_CITY_SUCCESS:
      return mergeDeepRight(state, {
        [payload.cityTitle]: {
          fetchHoodsByCitySuccess: true,
          data: payload.hoods.reduce(
            (acc, { hoodTitle, ratings, reviewsAmount }) => ({
              ...acc,
              [hoodTitle]: {
                ratings,
                reviewsAmount,
                totalRating: getTotalRating(ratings, payload.ratingFilters),
                loaded: true,
                error: false,
              },
            }),
            {}
          ),
        },
      });

    case SET_RATING_FILTERS:
    case TOGGLE_ALL_RATING_FILTERS:
      let newState = { ...state };

      for (let cityTitle in state) {
        if (state.hasOwnProperty(cityTitle)) {
          for (let hoodTitle in state[cityTitle].data) {
            if (state[cityTitle].data.hasOwnProperty(hoodTitle)) {
              newState[cityTitle].data[hoodTitle] = {
                ...state[cityTitle].data[hoodTitle],
                totalRating: getTotalRating(
                  state[cityTitle].data[hoodTitle].ratings,
                  payload.ratingFilters
                ),
              };
            }
          }
        }
      }

      return newState;

    default:
      return state;
  }
}
