import React from 'react';
import * as Sentry from '@sentry/browser';

import { Auth0Provider } from './src/utils/auth';

export const onClientEntry = () => {
  if (process.env.NODE_ENV === 'production') {
    Sentry.init({
      dsn:
        'https://a1847a82061a435baf6eb8be331d2b83@o398368.ingest.sentry.io/5253963',
    });
  }
};

export const wrapRootElement = ({ element }) => (
  <Auth0Provider
    domain={process.env.AUTH0_DOMAIN}
    client_id={process.env.AUTH0_CLIENT_ID}
    redirect_uri={process.env.AUTH0_DEFAULT_REDIRECT_URI}
    cacheLocation="localstorage"
  >
    {element}
  </Auth0Provider>
);
