import { SUBMIT_USERS_REVIEW_SUCCESS } from '../usersReview/constants';
import { FETCH_HOOD_SUCCESS } from '../hoods/constants';
import {
  FETCH_REVIEWS_SUCCESS,
  DELETE_REVIEW_SUCCESS,
} from '../reviews/constants';

const initialState = {};

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case SUBMIT_USERS_REVIEW_SUCCESS:
    case FETCH_REVIEWS_SUCCESS:
    case DELETE_REVIEW_SUCCESS:
    case FETCH_HOOD_SUCCESS: {
      return {
        ...state,
        ...(payload.users &&
          payload.users.reduce(
            (acc, { authId, name, picture, reviewsByCityAmount }) => ({
              ...acc,
              [authId]: { name, picture, reviewsByCityAmount },
            }),
            {}
          )),
      };
    }

    default:
      return state;
  }
}
