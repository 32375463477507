import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { load, save } from 'redux-localstorage-simple';

import hoodsReducer from './hoods/reducer';
import usersReviewReducer from './usersReview/reducer';
import reviewsReducer from './reviews/reducer';
import settingsReducer from './settings/reducer';
import mapReducer from './map/reducer';
import usersReducer from './users/reducer';
import modalsReducer from './modals/reducer';

export default (preloadedState) => {
  return createStore(
    combineReducers({
      hoodsReducer,
      usersReviewReducer,
      reviewsReducer,
      settingsReducer,
      mapReducer,
      modalsReducer,
      usersReducer,
    }),
    getLoadedState(preloadedState),
    applyMiddleware(
      thunk,
      save({ states: ['settingsReducer', 'usersReviewReducer'] })
    )
  );
};

const getLoadedState = (preloadedState) => {
  if (typeof window !== 'undefined')
    return {
      ...preloadedState,
      ...load({
        states: ['settingsReducer', 'usersReviewReducer'],
        disableWarnings: true,
      }),
    };

  return {
    ...preloadedState,
  };
};
