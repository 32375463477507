import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useIntl } from 'gatsby-plugin-intl';

import useSiteMetadata from '../../hooks/use-site-metadata';

const SEO = ({ pageTitle, pageImage, slug }) => {
  const intl = useIntl();

  const { title, description, url, image } = useSiteMetadata();

  const localizedTitle = intl.formatMessage({
    id: 'main.siteMetadata.title',
    defaultMessage: title,
  });
  const localizedDescription = intl.formatMessage({
    id: 'main.siteMetadata.description',
    defaultMessage: description,
  });

  const metaImage = pageImage ? `${url}${pageImage}` : `${url}${image}`;
  const metaTitle = pageTitle
    ? `${pageTitle} | ${localizedTitle}`
    : localizedTitle;
  const metaUrl = slug
    ? `${url}/${intl.locale}${slug}`
    : `${url}/${intl.locale}/`;

  return (
    <Helmet
      htmlAttributes={{
        lang: intl.locale,
      }}
    >
      {/* General tags */}
      <title>{metaTitle}</title>
      <meta name="description" content={localizedDescription} />
      <meta name="image" content={metaImage} />
      <meta
        name="google-site-verification"
        content="K-nJkMfxD_gm7VhkY4SRpy_-QHo4neeuNsXwK-wf0t4"
      />

      {/* OpenGraph tags */}
      <meta property="og:url" content={metaUrl} />
      <meta property="og:title" content={metaTitle} />
      <meta property="og:description" content={localizedDescription} />
      <meta property="og:image" content={metaImage} />
      <meta property="og:type" content="website" />
      <meta property="fb:app_id" content="1095556000818247" />

      {/* Twitter Card tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={metaTitle} />
      <meta name="twitter:description" content={localizedDescription} />
      <meta name="twitter:image" content={metaImage} />
    </Helmet>
  );
};

SEO.propTypes = {
  pageTitle: PropTypes.string,
  pageImage: PropTypes.string,
  slug: PropTypes.string,
};

export default SEO;
