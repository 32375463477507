import { mergeDeepRight } from 'ramda';

import {
  SET_HOOD_LIST_SORTING_TYPE,
  SET_RATING_FILTERS,
  TOGGLE_ALL_RATING_FILTERS,
  SET_HOOD_LIST_SEARCH_QUERY,
  SET_LOG_OUT_START,
  SET_LOG_IN_START,
  TOGGLE_IS_MOBILE_MAP_HIDDEN,
} from './constants';
import { HOOD_LIST_REVIEWS_AMOUNT_SORTING } from '../../utils/sorting-helper';
import { getRatingFilters } from '../../utils/rating-helper';

const initialState = {
  hoodListSortingType: HOOD_LIST_REVIEWS_AMOUNT_SORTING,
  ratingFilters: getRatingFilters(true),
  isLoggedIn: false,
  redirectTo: '/',
  searchQuery: '',
  isMobileMapHidden: false,
};

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case SET_HOOD_LIST_SORTING_TYPE: {
      return mergeDeepRight(state, { hoodListSortingType: payload });
    }

    case SET_HOOD_LIST_SEARCH_QUERY: {
      return mergeDeepRight(state, { searchQuery: payload });
    }

    case TOGGLE_IS_MOBILE_MAP_HIDDEN: {
      return mergeDeepRight(state, {
        isMobileMapHidden: !state.isMobileMapHidden,
      });
    }

    case SET_RATING_FILTERS: {
      return mergeDeepRight(state, {
        ratingFilters: { [payload.type]: payload.checked },
      });
    }

    case TOGGLE_ALL_RATING_FILTERS:
      return mergeDeepRight(state, {
        ratingFilters: payload.ratingFilters,
      });

    case SET_LOG_OUT_START:
    case SET_LOG_IN_START: {
      return mergeDeepRight(state, {
        redirectTo:
          payload.redirectTo &&
          !payload.redirectTo.includes('login') &&
          !payload.redirectTo.includes('logout')
            ? payload.redirectTo
            : state.redirectTo,
      });
    }

    default:
      return state;
  }
}
