import {
  SELECT_HOOD,
  HIGHLIGHT_HOOD,
  SET_MAP_IS_VISIBLE,
  SET_LIST_SCROLL_TOP,
} from './constants';

const initialState = {
  selectedHood: null,
  highlightedHoodFeatureId: null,
  mapIsVisible: false,
  listScrollTopObject: {},
};

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case SELECT_HOOD: {
      return {
        ...state,
        selectedHood: payload,
        highlightedHoodFeatureId: null,
      };
    }

    case HIGHLIGHT_HOOD: {
      return {
        ...state,
        highlightedHoodFeatureId: payload,
      };
    }

    case SET_MAP_IS_VISIBLE:
      return {
        ...state,
        mapIsVisible: payload,
      };

    case SET_LIST_SCROLL_TOP:
      return {
        ...state,
        listScrollTopObject: {
          ...state.listScrollTopObject,
          [payload.cityTitle]: payload.scrollTop,
        },
      };

    default:
      return state;
  }
}
