import { mergeDeepRight } from 'ramda';

import {
  DELETE_REVIEW_ERROR,
  DELETE_REVIEW_SUCCESS,
  DELETE_REVIEW_START,
  FETCH_REVIEWS_ERROR,
  FETCH_REVIEWS_START,
  FETCH_REVIEWS_SUCCESS,
} from './constants';
import { SUBMIT_USERS_REVIEW_SUCCESS } from '../usersReview/constants';

const initialState = {};

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case FETCH_REVIEWS_ERROR:
      return mergeDeepRight(state, {
        [payload.cityTitle]: {
          [payload.hoodTitle]: {
            error: payload.name,
            loaded: true,
          },
        },
      });

    case FETCH_REVIEWS_SUCCESS:
      return mergeDeepRight(state, {
        [payload.cityTitle]: {
          [payload.hoodTitle]: {
            error: false,
            loaded: true,
            reviews: payload.reviews,
          },
        },
      });

    case FETCH_REVIEWS_START: {
      return mergeDeepRight(state, {
        [payload.cityTitle]: {
          [payload.hoodTitle]: {
            error: false,
            loaded: false,
            reviews: [],
          },
        },
      });
    }

    case DELETE_REVIEW_SUCCESS:
      return mergeDeepRight(state, {
        [payload.cityTitle]: {
          [payload.hoodTitle]: {
            error: false,
            loaded: true,
            deleteError: false,
            deleteLoading: false,
            reviews: state[payload.cityTitle][payload.hoodTitle].reviews.filter(
              (review) => review.authorAuthId !== payload.authorAuthId
            ),
          },
        },
      });

    case DELETE_REVIEW_START: {
      return mergeDeepRight(state, {
        [payload.cityTitle]: {
          [payload.hoodTitle]: {
            deleteError: false,
            deleteLoading: true,
          },
        },
      });
    }

    case DELETE_REVIEW_ERROR: {
      return mergeDeepRight(state, {
        [payload.cityTitle]: {
          [payload.hoodTitle]: {
            deleteError: true,
            deleteLoading: false,
          },
        },
      });
    }

    case SUBMIT_USERS_REVIEW_SUCCESS: {
      return mergeDeepRight(state, {
        [payload.cityTitle]: {
          [payload.hoodTitle]: {
            error: false,
            loaded: true,
            reviews: [
              ...payload.reviews,
              ...(state[payload.cityTitle] &&
              state[payload.cityTitle][payload.hoodTitle]
                ? state[payload.cityTitle][payload.hoodTitle].reviews || []
                : []),
            ],
          },
        },
      });
    }

    default:
      return state;
  }
}
