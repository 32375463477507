import safetyIcon from '../img/icons/rating/safety.svg';
import nightIcon from '../img/icons/rating/night.svg';
import familyIcon from '../img/icons/rating/family.svg';
import transportIcon from '../img/icons/rating/transport.svg';
import greenIcon from '../img/icons/rating/green.svg';
import shopsIcon from '../img/icons/rating/shops.svg';
import restaurantsIcon from '../img/icons/rating/restaurants.svg';
import cultureIcon from '../img/icons/rating/culture.svg';
import costIcon from '../img/icons/rating/cost.svg';
import sportIcon from '../img/icons/rating/sport.svg';

const RATING_TYPE_COST = 'cost';
const RATING_TYPE_FAMILY = 'family';
const RATING_TYPE_NIGHT = 'night';
const RATING_TYPE_SAFETY = 'safety';
const RATING_TYPE_TRANSPORT = 'transport';
const RATING_TYPE_GREEN = 'green';
const RATING_TYPE_SHOPS = 'shops';
const RATING_TYPE_CULTURE = 'culture';
const RATING_TYPE_SPORT = 'sport';
const RATING_TYPE_RESTAURANTS = 'restaurants';

export const ratingFields = [
  RATING_TYPE_SAFETY,
  RATING_TYPE_NIGHT,
  RATING_TYPE_FAMILY,
  RATING_TYPE_TRANSPORT,
  RATING_TYPE_GREEN,
  RATING_TYPE_SHOPS,
  RATING_TYPE_RESTAURANTS,
  RATING_TYPE_CULTURE,
  RATING_TYPE_COST,
  RATING_TYPE_SPORT,
];

export const iconsMap = {
  safety: safetyIcon,
  night: nightIcon,
  family: familyIcon,
  transport: transportIcon,
  green: greenIcon,
  shops: shopsIcon,
  restaurants: restaurantsIcon,
  culture: cultureIcon,
  cost: costIcon,
  sport: sportIcon,
};

export const getTotalRating = (ratings, ratingFilters) => {
  let sum = 0;
  let amount = 0;

  Object.keys(ratings).forEach((key) => {
    if (ratingFilters[key]) {
      sum += ratings[key];
      amount += 1;
    }
  });

  return amount ? (sum / amount).toFixed(2) : 0;
};

export const getRatingFilters = (state) =>
  ratingFields.reduce((acc, field) => ({ ...acc, [field]: state }), {});
